import React from 'react';
import { Asset } from 'contentful';

import { ISideBySideHeroFields } from '../../../types/contentful';

import { getImageData } from '../../utils/contentful';

import RichText from '../RichText';
import { Text } from '../Text';
import { BlockContainer, Layout, Column } from '../Layout';
import { Box } from '../Box';
import Image from '../Image';

import {
  TextContainer,
  DesktopImageContainer,
  MobileImageContainer
} from './styles';

export function SideBySideHero({
  copy,
  headingText,
  image,
  desktopImage
}: ISideBySideHeroFields) {
  return (
    <BlockContainer
      as="section"
      marginTop="blockMargin"
      revealOnScroll={false}
      data-blockid="hero-5050"
      data-testid="hero-5050"
      data-e2e="hero"
    >
      <Layout
        gutter={{ 0: 'ml', desktop: 'l' }}
        flexDirection={{ 0: 'column-reverse', tablet: 'row' }}
        alignItems="center"
      >
        <Column
          size={{ 0: 12, tablet: 4 }}
          paddingBottom={{ 0: 'xs' }}
          marginRight={{ tablet: 'xl' }}
        >
          <TextContainer flexDirection="column">
            <Text
              as="h1"
              fontWeight="h1"
              fontSize={{ 0: 'h1_0', desktop: 'h1_tablet' }}
              colour="defaultText"
              marginBottom="m"
              data-blockid="hero-5050-heading"
            >
              {headingText}
            </Text>
            {copy && (
              <Box marginBottom={{ 0: 'ml' }} data-blockid="hero-5050-copy">
                <RichText data={copy} />
              </Box>
            )}
          </TextContainer>
        </Column>
        <DesktopImageContainer size={6}>
          <Image
            {...getImageData(desktopImage as Asset)}
            loading="eager"
            heroImage={true}
          />
        </DesktopImageContainer>
        <MobileImageContainer size={12}>
          <Image {...getImageData(image)} loading="eager" heroImage={true} />
        </MobileImageContainer>
      </Layout>
    </BlockContainer>
  );
}
