import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';
import { Column } from '../Layout';
import { Flexbox } from '../Flexbox';

export const TextContainer = styled(Flexbox)`
  text-align: center;
  ${mediaQueries.tablet} {
    text-align: left;
  }
`;

export const MobileImageContainer = styled(Column)`
  display: block;

  ${mediaQueries.tablet} {
    display: none;
  }
`;

export const DesktopImageContainer = styled(Column)`
  display: none;

  ${mediaQueries.tablet} {
    display: block;
  }
`;
